<template>
  <div id="app">
    <MediaList :showDownload="true" @file-clicked="handleAddFilesClicked" />
  </div>
</template>

<script>
import MediaList from "./SubComponents/MediaList.vue";

export default {
  components: {
    MediaList,
  },
  methods: {
    handleAddFilesClicked(item) {
      console.log("Przycisk Dodaj pliki został kliknięty", item);
    },
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1 {
  margin-bottom: 20px;
}
</style>
